<template>
    <div class="container-fluid">
        <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
            <div v-if="(id > 0)" class="col-sm-6">
                <h1>{{ $t('general.especialidad') }}</h1>
            </div>
            <div v-else class="col-sm-6">
                <h1>{{ $t('general.nuevaespecialidad') }}</h1>
            </div>
            </div>
        </div><!-- /.container-fluid -->
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación de una especialidad -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos Especialidad -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{especialidad.nombre}}</h3>
                            <h3 class="profile-username text-center" @actualizarpadre="actualizarpadrefunc()">{{ nombre }}</h3>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>{{ $t('general.asignadoaoperarios') }}</b> <a class="float-right">31</a>
                            </li>
                            <li class="list-group-item">
                                <b>{{ $t('general.visitasactivas') }}</b> <a class="float-right">45</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <div class="callout callout-info">
                    <h5>{{ $t('general.ultimamodificacion') }}</h5>
                    <p>15/12/2021 15:35h</p>
                    </div>
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_especialidad" data-toggle="tab">{{ $t('general.datos') }}</a></li>  
                                <li class="nav-item"><a class="nav-link" href="#equipos_especialidad" data-toggle="tab">{{ $t('general.equipos') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <br>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_especialidad">
                            <especialidad-datos :id="id" :especialidad="this.especialidad" />
                        </div>
                        <div v-if="(id > 0)" class="tab-pane" id="equipos_especialidad">
                            <especialidad-equipos :id="id" :especialidad="this.especialidad" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import EspecialidadDatos from './datos/EspecialidadDatos.vue'
import EspecialidadEquipos from './equipos/EspecialidadEquipos.vue'
import { PwgsApi } from '../../../services/PwgsApi'
export default {
    props: {
        id: {
            type: Number,
            default: 0,
        }
    },
    components: {
        'especialidad-datos': EspecialidadDatos,
        'especialidad-equipos': EspecialidadEquipos,
    },
    data() {
        return {
            nombre:"",
            especialidad: [],      
        }
    },
    methods: {
        async obtenerEspecialidad(id) {
            /* get  modulos/pwgsapi/index.php/especialidades/:id */
            const api = new PwgsApi;
            const datos = await api.get('especialidades/' + id);
            this.especialidad = datos.datos;
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/especialidades/" + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.especialidad.nombre;
                    this.$store.getters.ventanas[i].alt = this.especialidad.nombre;
                }
            }
            /**/
            return this.especialidad;
        },
    },
    watch:
    {
        id(){
            this.obtenerEspecialidad(this.id);
        },   
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/especialidades/" + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.especialidad.nombre;
                    this.$store.getters.ventanas[i].alt = this.especialidad.nombre;
                }
            }
            /**/
        }
    },
    mounted() { 
        this.obtenerEspecialidad(this.id);
    },
}
</script>
