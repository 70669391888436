<template>
    <div class="row">
        <div class="col-md-5">
            <seccion-datos :titulo="datosespecialidad" @cambios="guardarEspDatos()">
                    <div class="form-group">
                        <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                        <input type="text" class="form-control nombre_completo dato" id="nombre_completo" v-model='nombre' placeholder="Nombre Especialidad">
                    </div>
                    <div class="form-group">
                    <div class="form-check form-switch">
                        <input type="hidden" name="my-checkbox" value="0">
                        <input class="form-check-input" name="my-checkbox" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="pendiente" :true-value="2" :false-value="1">
                        <label class="form-check-label" for="flexSwitchCheckDefault"><b>{{ $t('general.especialidadpendiente') }}</b></label>
                    </div>
                    </div>
                </seccion-datos>
            </div>
        </div> 
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'especialidad'],
    data() {
        return {
            nombre: null,
            pendiente: "",
            datosespecialidad: "Datos"
        }
    },
        watch: {
            especialidad() {
                this.cargardatos();
            }
        },
         methods: {
            cargardatos() {
                this.$store.dispatch('cambiarCargando', true);
                this.nombre = this.especialidad.nombre;
                this.pendiente = this.especialidad.estado_pendiente;
                this.datosespecialidad = this.$t("general.datos");
                this.$store.dispatch('cambiarCargando', false);
            },
            async guardarEspDatos() {
                const subidadatos = { nombre: this.nombre, estado_pendiente: this.pendiente}; 
                const api = new PwgsApi;
                if (this.id > 0) {
                    try {
                        /* put  modulos/pwgsapi/index.php/especialidades/:id */
                        this.$store.dispatch('cambiarGuardando', true);
                        await api.put('especialidades/' + this.especialidad.id, subidadatos);
                        this.$parent.obtenerEspecialidad(this.especialidad.id);
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                        this.$store.dispatch('cambiarGuardando', false);
                    }
                    catch (error) {
                        this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
                }
                else
                { 
                    /* post  modulos/pwgsapi/index.php/especialidades */
                    try {
                        let res;
                        res = await api.post('especialidades/' + this.id, subidadatos);
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                        this.$router.push('/especialidades/' + res.id);
                    }
                    catch (error) {
                        this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
                }
                }
        } 
}
</script>